import { SpaceType } from '.'

export enum SpacentSellableType {
  consumerCoworkingPass = 'consumerCoworkingPass',
  coworkingPass5persons = 'coworkingPass5persons',
  coworkingPass10persons = 'coworkingPass10persons',
  subscriptionTest = 'subscriptionTest',
  volumeBasedReservations = 'volumeBasedReservations',
}

export type SpacentSellablePriceTypes =
  | 'fixedMonthly'
  | 'reservationPriceBySpaceType'

export type SpacentSellableEditorType = SpacentSellablePriceTypes

export type SpacentSellable = FixedMonthlyPrice | ReservationPriceBySpaceType

export interface PriceWithStripeId {
  priceWithoutTax: number
  stripePriceId: string
}

export type FixedMonthlyPrice = PriceWithStripeId

type PricePerReservation = PriceWithStripeId

export type ReservationPriceBySpaceType = {
  [key in SpaceType]: PricePerReservation | undefined
}

// TODO: combine the following maps into a single map
export const sellableEditorTypes: Record<
  SpacentSellableType,
  SpacentSellableEditorType
> = {
  [SpacentSellableType.consumerCoworkingPass]: 'fixedMonthly',
  [SpacentSellableType.coworkingPass5persons]: 'fixedMonthly',
  [SpacentSellableType.coworkingPass10persons]: 'fixedMonthly',
  [SpacentSellableType.subscriptionTest]: 'fixedMonthly',
  [SpacentSellableType.volumeBasedReservations]: 'reservationPriceBySpaceType',
}

export type SpacentSellableMapping = {
  [SpacentSellableType.consumerCoworkingPass]: FixedMonthlyPrice
  [SpacentSellableType.coworkingPass5persons]: FixedMonthlyPrice
  [SpacentSellableType.coworkingPass10persons]: FixedMonthlyPrice
  [SpacentSellableType.subscriptionTest]: FixedMonthlyPrice
  [SpacentSellableType.volumeBasedReservations]: ReservationPriceBySpaceType
}

export type SomeSpacentSellables = Partial<{
  [K in keyof SpacentSellableMapping]: SpacentSellableMapping[K]
}>

export const spacentSellablesInOrder: SpacentSellableType[] = [
  SpacentSellableType.consumerCoworkingPass,
  SpacentSellableType.coworkingPass5persons,
  SpacentSellableType.coworkingPass10persons,
  SpacentSellableType.volumeBasedReservations,
]
