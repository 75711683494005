// Shared types for both backend server and admin frontend

import {
  adminRoles,
  amenities,
  currencyCodes,
  languages,
  possibleUserFeatureFlags,
  publishingStatuses,
  taxRateCountryCodes,
  taxTypes,
  weekDays,
  HeardFromSource,
  spaceTypes,
} from '../constants'
import { PricingSummary } from './pricing'
import { Moment } from 'moment/moment'
import { SomeSpacentSellables } from './spacent_sellables'

export * from './spacent_sellables'

export type AuthMethod = 'google' | 'azure' | 'email'

export type InvoiceType = 'electronic' | 'pdf' | 'credit_card' | null

export type Language = typeof languages[number]
export type LocalizedText = { [l in Language]?: string }
export type LocalizedTextError = LocalizedText

export type LocalizedTextWithDefaultLanguage = LocalizedText & {
  default: Language
}

export enum IntegrationInputType {
  LIST = 1,
  INPUT = 2,
  SUPER_ADMIN_INPUT = 3,
  SUPER_ADMIN_LIST = 4,
}

export enum IntegrationType {
  GOOGLE_SERVICE_ACCOUNT = 0, // Service Account
  AZURE = 2, // Service Principal
  EWS = 3, // Microsoft Exchange Web Service
  ASIO = 4,
  OFFICE_RND = 5,
  NEXUDUS = 6,
  TIME_WORKS = 7,
  PINDORA = 8,
  ID_CONTROL = 9,
  ACCESSY = 10,
  VARIAN = 11,
  RATNA = 12,
  ILOQ = 13,
  UNI_HELSINKI = 14,
  TTK = 15, // Turun teknologiakiinteistöt,
  BOKUN = 16,
  TAITORI = 17,
  LEGA_ONLINE = 18,
  STATIC_PIN = 19,
  EPICENTER = 20,
  STATIC_PIN_FROM_LIST = 21,
  PARAKEY = 22,
  ZILAR = 23,
  OPERA = 24,
  ANDCARDS = 25,
  WORKLAND = 26,
  SALTOKS = 27,
  ACCESSPOINT = 28,
}

export const integrationInputs: {
  [key in IntegrationType]?: IntegrationInputType
} = {
  [IntegrationType.ASIO]: IntegrationInputType.LIST,
  [IntegrationType.NEXUDUS]: IntegrationInputType.LIST,
  [IntegrationType.EWS]: IntegrationInputType.SUPER_ADMIN_INPUT,
  [IntegrationType.OFFICE_RND]: IntegrationInputType.LIST,
  [IntegrationType.VARIAN]: IntegrationInputType.LIST,
  [IntegrationType.TTK]: IntegrationInputType.LIST,
  [IntegrationType.ANDCARDS]: IntegrationInputType.LIST,
  [IntegrationType.BOKUN]: IntegrationInputType.LIST,
  [IntegrationType.TAITORI]: IntegrationInputType.LIST,
  [IntegrationType.AZURE]: IntegrationInputType.SUPER_ADMIN_LIST,
  [IntegrationType.LEGA_ONLINE]: IntegrationInputType.LIST,
  [IntegrationType.TIME_WORKS]: IntegrationInputType.LIST,
  [IntegrationType.EPICENTER]: IntegrationInputType.LIST,
  [IntegrationType.GOOGLE_SERVICE_ACCOUNT]:
    IntegrationInputType.SUPER_ADMIN_LIST,
  [IntegrationType.OPERA]: IntegrationInputType.LIST,
  [IntegrationType.WORKLAND]: IntegrationInputType.LIST,
} as const

export interface AvailableCalendar {
  calendarId: string
  integrationId: number
  name: string
}

export const paymentMethods = [
  'invoice',
  'tractr_invoice',
  'host_invoice',
  'cash',
  'subscription',
  'credit_card',
] as const
export type PaymentMethod = typeof paymentMethods[number]

export enum PaymentMethods {
  Invoice = 'invoice',
  TractrInvoice = 'tractr_invoice',
  HostInvoice = 'host_invoice',
  Cash = 'cash',
  Subscription = 'subscription',
  CreditCard = 'credit_card',
}

export type ReservationPaymentStatus =
  | 'succeeded'
  | 'waiting_for_authorization'
  | 'pending'
  | 'failed'

export type PriceGroup = string

export type ClientType = 'app' | 'admin' | 'web' | 'invite'

export type CancellingUserType = 'host' | 'user' | 'server' | 'super-admin'

export type ReservationType = 'single' | 'capacity'

export type SpaceType = typeof spaceTypes[number]

export type TaxType = typeof taxTypes[number]

export type TaxRateCountryCode = typeof taxRateCountryCodes[number]

export type CurrencyCode = typeof currencyCodes[number]

export type AdminRole = typeof adminRoles[number]

export type Amenities = typeof amenities[number]

export type PublishingStatus = typeof publishingStatuses[number]
export type LocationPublishingStatus = Exclude<PublishingStatus, 'inReview'>

export interface Product {
  id: number
  title: string
  price: number
  currencyCode: CurrencyCode
  vat: number
}

export interface ProductQuantity {
  quantity: number
  productId: number
}

// TODO: Make this use Product instead of duplicating the fields
export interface AdminProductQuantity extends ProductQuantity {
  title: string
  unitPrice: number
  currencyCode: CurrencyCode
}

export interface TaxRate {
  oldRate?: number
  cutoff?: DateISOString
  rate: number
}

export interface User {
  id: number
  email: string
  name?: string
  phone?: string
  roles: AdminRole[]
  verified: boolean
  disabled: boolean
  organizationId?: number
  realOrganizationId?: number
  acceptedTerms: boolean | null
  isSubscriptionMember?: boolean
  overrideAuthMethod?: AuthMethod | null
  groupIds?: number[]
  legislationRegionId?: number
  organizationLegislationRegionId?: number // vat rates depend on organizations agreements so we also need this
  stripeCustomerToken?: string
  features: FeatureFlags | null
  ignoreOrganizationPayPerUseLimitation: boolean
  passedSignupCode: boolean
  discountId?: number
  canSeeColleagues: boolean
  canHaveCreditCard: boolean
  deleted?: DateISOString
  heardFromSource?: HeardFromSource
  validJwt?: boolean
  promptedForHomeAddress?: DateISOString
  seenHomeAddressIntro?: boolean
  askedForHomeAddress?: DateISOString
  homeAddressSet?: boolean
  commutePreferenceSet?: boolean
  disallowReservations?: boolean
  taxRateForReservations?: TaxRate // TODO: move this to a separate context object instead of user
  latestReservation?: DateISOString
  avatarUrl?: string
}

export interface ImpactParams {
  numberOfEmployees: number
  mobileWorkersPercent: number
  daysInFlexLocations: number
  daysWorkingFromHome: number
  fixedOfficeSize?: number
  areaPerEmployee?: number
  fixedOfficeCostPerM2: number
  currency: ImpactCurrency
  flexCost: number // per hour per m2
}

interface ImpactResultsTotals {
  potentialForFixedLocationDownsizing: number // m^2
  potentialForFixedLocationDownsizingRelative: number // percent
  totalCostSavings: number // currency / month
  totalCostSavingsRelative: number // percent
  occupancyCostSavings: number // currency / month / FTE
  co2Saved: number // kg / year, not including commuting
}

interface ImpactResultsTraditional {
  fixedOfficeSize: number // m^2
  fixedOfficeCost: number // total currency / month
}

interface ImpactResultsHybrid {
  reducedFixedOfficeSize: number // m^2
  reducedFixedOfficeCost: number // total currency / month
  requiredFlexUse: number // hours / month
  flexUseCost: number // currency / month
  totalHybridWorkplaceCosts: number // currency / month
}

export interface ImpactResults {
  totals: ImpactResultsTotals
  traditional: ImpactResultsTraditional
  hybrid: ImpactResultsHybrid
  currency: ImpactCurrency
}

type ImpactCurrency = 'EUR' | 'SEK' | 'NOK' | 'DKK'

export const impactCurrencies: {
  value: ImpactCurrency
  symbol: string
}[] = [
  {
    value: 'EUR',
    symbol: '€',
  },
  {
    value: 'SEK',
    symbol: 'kr',
  },
  {
    value: 'NOK',
    symbol: 'kr',
  },
  {
    value: 'DKK',
    symbol: 'kr',
  },
]

export type OccStatsDayField =
  | 'ownOrgResCount'
  | 'networkResCount'
  | 'desksResCount'
  | 'netDesksResCount'
  | 'ownDesksResCount'
  | 'meetingResCount'
  | 'netMeetingResCount'
  | 'ownMeetingResCount'
  | 'meetingResHours'
  | 'netMeetingResHours'
  | 'ownMeetingResHours'
  | 'projectResCount'
  | 'netProjectResCount'
  | 'ownProjectResCount'
  | 'teamResCount'
  | 'netTeamResCount'
  | 'ownTeamResCount'

export type OccStatsDay = {
  day: DateISOString
} & {
  [key in OccStatsDayField]: number
}

export interface OccStatsLocation {
  id: number
  buildingName: string
  city: string
  reservationCount: number
  visitorCount: number
  ratingsAvg: number
  ratingsCount: number
}

export interface OccStatsWeekday {
  dayOfWeek: number // Starts from 1 = Monday
  allResCount: number
}

export interface OccStatsRoom {
  roomId: number
  roomAndBuildingName: string
  ownOrgResCount: number
}

export interface OccStats {
  usersCount: number
  activeUsersCount: number
  resCount: number
  reserverCount: number
  ownOrgResCount: number
  ownOrgReserverCount: number
  networkResCount: number
  networkReserverCount: number
  desksResCount: number
  meetingResCount: number
  projectResCount: number
  teamResCount: number
  netDesksResCount: number
  netMeetingResCount: number
  netProjectResCount: number
  netTeamResCount: number
  ownDesksResCount: number
  ownMeetingResCount: number
  ownProjectResCount: number
  ownTeamResCount: number
  networkDailyResCountMax: number
  desksDailyResCountMax: number
  meetingDailyHourCountMax: number
  cancelledResCount: number
  meetingHoursSum: number
  meetingHoursAvg: number
  ownMeetingHoursSum: number
  ownMeetingHoursAvg: number
  netMeetingHoursSum: number
  netMeetingHoursAvg: number
  ownProjectHoursSum: number
  ownTeamHoursSum: number
  ownProjectHoursAvg: number
  ownTeamHoursAvg: number
  netProjectHoursSum: number
  netTeamHoursSum: number
  netProjectHoursAvg: number
  netTeamHoursAvg: number
  ownDesksHoursSum: number
  ownDesksHoursAvg: number
  netDesksHoursSum: number
  netDesksHoursAvg: number
  networkHoursSum: number
  networkHoursAvg: number
  ratingsAvg: number
  ratingsCount: number
  daily: OccStatsDay[]
  topNetworkLocations: OccStatsLocation[]
  weekDaily: OccStatsWeekday[]
  ownOrgRooms: OccStatsRoom[]
}

export type OccStatsWithComparison = {
  stats: OccStats
  comparisonStats: OccStats
  comparisonDescription: string
  comparisonDescriptionShort: string
  comparisonStart: DateISOString
  comparisonEnd: DateISOString
  hasOwnSpaces: boolean
} | null

interface HostStatsDurationCount {
  count: number
  duration: number
}

export interface HostStats {
  weekDaily: OccStatsWeekday[]
  cancelledResCount: number
  resCount: number
  ownOrgRooms: OccStatsRoom[]
  ratingsAvg: number
  ratingsCount: number
  usedCapacity: number
  durationCounts: HostStatsDurationCount[]
}

export interface UsersCount {
  day: DateISOString
  usersCount: number
}

export interface ActiveUsersCount extends UsersCount {
  total: number
  ofTotal: number
}

export interface UserStats {
  total: UsersCount[]
  active: ActiveUsersCount[]
}

export interface Organization {
  aliases?: string[]
  auth: AuthMethod
  domains?: string[]
  locationDetectionAllowed: boolean
  showOnlyOrganizationRooms: boolean
  storeOnlyUserEmail: boolean
  requiresReference?: boolean
  requiresReferenceOnSubscriptionReservations?: boolean
  hideContentfulStrips?: boolean
  eInvoiceAddress?: string
  eInvoiceOperator?: string
  id?: number
  identifier?: string
  invoiceContact?: string
  invoiceType: InvoiceType
  name: string
  pdfInvoiceReceiver?: string
  priceGroup?: string
  calendarIntegrationIds: number[]
  cardholderName?: string
  cardholderEmail?: string
  stripeCustomerToken?: string
  addressStreet?: string
  addressPostalCode?: string
  addressCity?: string
  addressCountry?: string
  legislationRegionId: number
  nexudusSubdomain?: string // TODO typing thought through
  limitPayPerUse: boolean
  preventPayPerUse: boolean
  signupCodeId?: number
  impactParams?: ImpactParams

  isHost: boolean
  isOccupier: boolean
  isDemo: boolean
  hasFixedLocation: boolean
}

export interface OrganizationSettings {
  requiresReference?: boolean
  requiresReferenceOnSubscriptionReservations?: boolean
  referenceInstructions?: string
}

export interface UserWithOrganization extends User {
  organization?: Organization
  implicitUsergroups?: number[]
  countryCode?: TaxRateCountryCode
}

export type MyOrganization = Pick<
  Organization,
  | 'id'
  | 'name'
  | 'identifier'
  | 'eInvoiceOperator'
  | 'eInvoiceAddress'
  | 'invoiceContact'
  | 'invoiceType'
  | 'pdfInvoiceReceiver'
  | 'auth'
  | 'cardholderName'
  | 'cardholderEmail'
  | 'stripeCustomerToken'
  | 'nexudusSubdomain'
  | 'isDemo'
  | 'isHost'
  | 'isOccupier'
  | 'domains'
>

export interface OrganizationWithBilling {
  id?: number
  name: string
  priceGroup: PriceGroup
  identifier?: string
  invoiceType: InvoiceType
  eInvoiceAddress?: string
  eInvoiceOperator?: string
  invoiceContact?: string
  pdfInvoiceReceiver?: string
  useOrganizationDomains?: boolean
  domains?: string[]
  auth: AuthMethod
  calendarIntegrationIds: number[]
  hasUsersWithSubscription?: boolean
  addressStreet?: string
  addressPostalCode?: string
  addressCity?: string
  addressCountry?: string
  stripeCustomerToken?: string
  cardholderName?: string
  cardholderEmail?: string
  legislationRegionId: number
  isHost: boolean
  isOccupier: boolean
  isDemo: boolean
  organizationReservationQuotas?: OrganizationReservationQuotaUnsaved[]
  limitPayPerUse: boolean
  preventPayPerUse: boolean
  requiresReference?: boolean
  requiresReferenceOnSubscriptionReservations?: boolean
  hideContentfulStrips?: boolean
  usergroupName?: string
  hasSharedMembership?: boolean
  joinedUsergroups?: number[]
  userLimit?: number
  upcomingReservationLimitPerUser?: number
  statisticsEmailOptions?: StatsEmailOptions
  fixedLocations?: FixedLocation[]
  localized?: OrganizationLocalized

  occupierBillingUseVat?: boolean
  occupierBillingVatPercentage?: number
  occupierBillingInternalNotes?: string
  manualOccupierBilling?: boolean
}

export interface OrganizationLocalized {
  referenceInstructions: LocalizedTextWithDefaultLanguage
}

export interface OrganizationWithUsage extends OrganizationWithBilling {
  meetingRoomQuotaHours: number
  registeredUsers: number
}

export interface ProductReservationCounts {
  resCount: number
  reserverCount: number
  coworkingPassResCount: number
  coworkingPassReserverCount: number
  meetingRoomQuotaResCount: number
  meetingRoomQuotaResHours: number
  meetingRoomQuotaReserverCount: number
  payPerUseResCount: number
  payPerUseReserverCount: number
}

export interface OrganizationWithActivity extends OrganizationWithUsage {
  stats: ProductReservationCounts
  comparisonStats: ProductReservationCounts
}

export interface OrganizationHostPaymentInfoDetails {
  manualHostPayment?: boolean
  hostPaymentInternalNotes?: string
  currencyCode: CurrencyCode
}

export interface OrganizationHostPaymentInfo
  extends OrganizationHostPaymentInfoDetails {
  hostPaymentGroups?: OrganizationHostPaymentGroup[]
  currentCommission?: OrganizationCommission
  commissions?: OrganizationCommission[]
}

export interface OrganizationHostPaymentGroup {
  id: number
  name: string
  paymentAccountIban?: string
  paymentAccountBic?: string
  paymentReference?: string
  buildingIds: number[]
  emails: string[]
}

type OrganizationId = any
export type ActiveCommissions = Map<OrganizationId, OrganizationHostPaymentInfo>

export interface OrganizationCommission {
  commissionPercentage?: number
  deskCompensationTaxFree?: number
  privateOfficeCompensationTaxFree?: number
  created?: DateISOString
  useVat?: boolean
  effectiveAfter?: DateISOString
  taxPercentage?: number
  id?: number
}

export interface LegislationRegion {
  id: number
  countryCode: TaxRateCountryCode
  regionalState?: string
  city?: string
  roomTaxPercentage: number
  roomTaxType: TaxType
  localLanguageCode: Language
  currencyCode: CurrencyCode
  currencyLocales: string[]
  subscriptionLocations?: number
  stripeSubscriptionTaxId?: string
  stripeSubscriptionTaxIdTest?: string
  generalTermsAndConditionsUrl?: string
  userTermsUrl?: string
  privacyPolicyUrl?: string
  smsSenderPhoneNumber?: string
  contentfulMarketingBundleId?: string
  active: boolean
  prices: SomeSpacentSellables
  localized: {
    feedback_url: LocalizedContent
  }
}

export interface OrganizationStripePaymentInfo {
  cardholderName: string
  cardholderEmail: string
  cardLastFourNumbers: string
  cardExpirationMonth: number
  cardExpirationYear: number
  cardBrand: string
  paymentMethodId: string
}

export enum SharedMembershipPricePoint {
  minUpTo5memberships = 5,
  twoToFiveMemberships = 5,
  maxUpTo10memberships = 10,
}

export type SharedMembershipPricePointId =
  keyof typeof SharedMembershipPricePoint

export interface OrganizationReservationQuotaUnsaved {
  starting: string
  memberships: number
  price?: number | null
}

export interface StatsEmailOptions {
  emails?: string[]
  sendTopLocations?: boolean
  sendReserverCount?: boolean
  sendRegisteredUsersCount?: boolean
  sendReservationsCount?: boolean
  sendDailyReservations?: boolean
  lastSent?: string
}

export interface OrganizationReservationQuota
  extends OrganizationReservationQuotaUnsaved {
  updated: Date
  updatedBy: string
}

export interface OrganizationReservationQuotaUtilization {
  memberships: number
  reservationsPerMembership: number
  reservationsProvided: number
  reservationsUsed: number
  reservationsAvailable: number
}

export interface OrganizationStripeSetupIntent {
  // clientSecret is generated by creating a new SetupIntent. THIS IS NEVER THE SECRET API KEY!
  clientSecret: string
  stripePublishableKey: string
}

export interface OnboardingOrganizationStripeSetupIntent
  extends OrganizationStripeSetupIntent {
  draftOrganizationId: string
}

export interface DefaultLocalization {
  default: Language
}

export type LocalizedTextWithDefault = LocalizedText & DefaultLocalization

export type LocalizedTextArray = {
  [l in Language]?: string[]
}
export type LocalizedTextArrayWithDefault = LocalizedTextArray &
  DefaultLocalization

export type RoomLocalizations = {
  name: LocalizedTextWithDefault
  description: LocalizedTextWithDefault
  arrivalInfo?: LocalizedTextWithDefault
  preReservationChecks?: LocalizedContent<PreReservationChecks>
}

export type BuildingLocalizations = {
  name: LocalizedTextWithDefault
  description: LocalizedTextWithDefault
  arrivalInfo?: LocalizedTextWithDefault
  title?: LocalizedTextWithDefault
  content?: LocalizedTextWithDefault
  checks?: LocalizedTextArrayWithDefault
  websiteUrl: LocalizedTextWithDefault
  metaTitleText: LocalizedTextWithDefault
  metaDescription: LocalizedTextWithDefault
  metaKeywords: LocalizedTextWithDefault
  openGraphTitleText: LocalizedTextWithDefault
  openGraphDescription: LocalizedTextWithDefault
  altTexts: LocalizedTextArrayWithDefault
  slug: LocalizedTextWithDefault
}

export interface Door {
  id: number
  externalId?: string
  integrationId?: number
}

export enum TimeSelectorType {
  singleDay = 'singleDay',
  multiDay = 'multiDay',
}

export type RoomOptions = {
  id: number
  name: string
  localized: RoomLocalizations
  address: string
  building: string
  country: string
  city: string
  pricingByGroup: Record<string, PricingRule[]>
  pricingDataByGroup?: Record<string, PricingData>
  currencyCode: CurrencyCode
  currencyLocales: string[]
  capacity: number
  displayCapacity: boolean
  allowedDaysMax: number
  reservationType: ReservationType
  spaceType: SpaceType
  roomCalendarIntegration?: RoomCalendarIntegration
  requireIntegrationSubscription: boolean
  doors: Door[]
  organizationId: number
  roomImageUrls: string[]
  floorplanImageUrl: string
  floor: number
  cancellableHoursBeforeStart: number
  external: boolean
  latitude: number
  longitude: number
  pricing: PricingRule[]
  pricingData?: PricingData
  reservations: ReservedSlot[]
  timezone: string
  allowedReservationTimes: AllowedReservationTimes
  buildingId: number
  buildingDetailsAvailable: boolean
  buildingVisitorInfoAvailable: boolean
  roomPreReservationChecksAvailable?: boolean
  maxReservationMinutes?: number
  minReservationMinutes?: number
  maxReservableAdvanceHours?: number
  minReservableAdvanceHours?: number
  maxReservationMinutesPerDay?: number
  maxUpcomingReservationsPerUser?: number
  supportedPaymentMethods: PaymentMethod[]
  buildingRoomSortOrder: number | null
  rating?: number
  description?: string
  amenities: Amenity[]
  combinedAmenities: Amenity[]
  coordinates: CoordinatesBySystem
  roomTaxPercentage: number
  roomTaxPercentageObj: TaxRate
  roomTaxType: TaxType
  publishingStatus: PublishingStatus
  isUnavailable?: boolean
  connectedRoomIds?: number[]
  hasConnectedRooms: boolean

  inviteGuestEnabled?: boolean

  preReservationBufferMins: number
  postReservationBufferMins: number

  isFavorite: boolean
  includedInMeetingRoomPackage?: boolean
  visiblePrice?: string
  consumerPayPerUseEnabled?: boolean
  businessPayPerUseEnabled?: boolean
  icalId?: string
  reserveTimeSelector?: TimeSelectorType
  legislationRegionId?: number
}

export type Room = Pick<
  RoomOptions,
  | 'id'
  | 'name'
  | 'localized'
  | 'capacity'
  | 'displayCapacity'
  | 'allowedDaysMax'
  | 'roomImageUrls'
  | 'floorplanImageUrl'
  | 'floor'
  | 'cancellableHoursBeforeStart'
  | 'external'
  | 'pricingByGroup'
  | 'pricingDataByGroup'
  | 'pricingData'
  | 'allowedReservationTimes'
  | 'roomCalendarIntegration'
  | 'reservationType'
  | 'spaceType'
  | 'amenities'
  | 'publishingStatus'
  | 'connectedRoomIds'
  | 'preReservationBufferMins'
  | 'postReservationBufferMins'
  | 'includedInMeetingRoomPackage'
  | 'consumerPayPerUseEnabled'
  | 'businessPayPerUseEnabled'
  | 'icalId'
>

export type NewRoom = Omit<Room, 'id'> & {
  buildingId: number
  currencyCode: CurrencyCode
  bookingNotifications?: BookingNotificationSettings
  showPreReservationChecks?: boolean
  defaultReservationLimits?: ReservationLimits
}

export type ConnectedRoom = Pick<
  RoomOptions,
  | 'id'
  | 'roomCalendarIntegration'
  | 'reservationType'
  | 'capacity'
  | 'displayCapacity'
  | 'allowedDaysMax'
  | 'preReservationBufferMins'
  | 'postReservationBufferMins'
  | 'timezone'
  | 'address'
  | 'allowedReservationTimes'
  | 'spaceType'
  | 'hasConnectedRooms'
  | 'icalId'
>

export type ConnectedRoomsByRoomId = { [roomId: number]: ConnectedRoom[] }

export type RoomForCalendarIntegration = Required<
  Pick<
    RoomOptions,
    | 'id'
    | 'roomCalendarIntegration'
    | 'address'
    | 'allowedReservationTimes'
    | 'capacity'
    | 'spaceType'
    | 'preReservationBufferMins'
    | 'postReservationBufferMins'
    | 'timezone'
  >
>

export type RoomUpdate = Room

export type Timezone = string

export interface Lobby {
  lobbyUsergroupId?: number
  openingHours?: string
  openingHoursStruct?: AllowedReservationTimes
  phone: string | null
  sms: string | null
  email: string | null
  emailLanguage: Language
  content: string // TODO: For backwards compatibility. Not used any more.
}

export type LocalizedLobby = { [l in Language]?: Lobby }

export type CoordinateSystem = 'gps' | 'mapbar' | 'baidu' | 'autonavi'

export type CoordinateData = {
  type: CoordinateSystem
  latitude: number
  longitude: number
}

export type CoordinatesBySystem = { [key in CoordinateSystem]?: CoordinateData }

export type Building = {
  id: number
  operatorName: string
  localized: BuildingLocalizations
  name: string
  latitude: number
  longitude: number
  timezone: Timezone
  address: string
  city: string
  cityObj?: City
  country: string
  description: string
  lobbyDescription: LocalizedLobby
  lobbyUsergroupId?: number
  lobbyUsergroupReservationsOnly?: boolean
  imageUrl: string
  maintenanceEmails?: string[]
  visitorInfoTitle?: string
  visitorInfoContent?: string
  visitorInfoChecks?: string[]
  arrivalInfo?: string
  wifiName: string | null
  wifiPassword: string | null
  allowedReservationTimes: AllowedReservationTimes
  newReservationNotifications: boolean
  amenities: Amenity[]
  publishingStatus: LocationPublishingStatus

  // Replaces latitude and longitude fields as a way of supporting multiple coordinate systems.
  coordinates?: CoordinatesBySystem

  showHealthCommitment: boolean
  useAutomaticNationalHolidays: boolean
} & Omit<Lobby, 'content'>

export type NewBuilding = Omit<Building, 'id' | 'timezone' | 'city'> & {
  imageUrls: string[]
  marketingImageUrl?: string
  localized: BuildingLocalizations
  cityObj: City
  createWebPage: boolean
}

export type BuildingListItem = Pick<
  Building,
  'id' | 'name' | 'imageUrl' | 'latitude' | 'longitude' | 'coordinates'
> & {
  roomCount: number
  buildingDetailsAvailable: boolean
  viewDate?: string
  colleagues?: Colleague[] | string[]
}

export interface FixedLocation {
  id?: number
  latitude: number
  longitude: number
  address?: string
  start: DateISOString
  end: DateISOString
}

export type Brand = {
  id: number
  showBrandSplashScreen: boolean
  localized: {
    name: LocalizedContent
  }

  logoUrl?: string
  coverUrl?: string
  brandColor?: string
}

export type MyLocationLane = {
  title: string
  placeholder?: string
  locations: BuildingListItem[]
  type: 'organization' | 'colleagues' | 'partner' | 'lastVisited'
  brand?: Brand
}

export type UserMyLocations = MyLocationLane[]

export type BuildingExportItem = Pick<
  Building,
  'id' | 'operatorName' | 'latitude' | 'longitude'
> & { availableForSubscription: boolean; availableForAll: boolean }

export type Amenity = {
  amenity: Amenities
  forExtraCharge?: boolean
}

interface AdminBuildingRoom extends Room {
  usergroups?: string[]
  unavailabilities?: RoomUnavailability[]
}

export type CityLocalizations = {
  name: LocalizedTextWithDefault
  url?: LocalizedTextWithDefault
}

export type City = {
  id?: number
  localized: CityLocalizations
  country: string
  canEdit?: boolean
  locationCount?: number
  availableSpaceTypes?: SpaceType[]
  imageUrl?: string
}

export interface CitySummary extends Required<City> {
  locations: {
    name: string
    id: number
    orgId: number
  }[]
  creator: string
}

export type AdminBuilding = {
  id: number
  organizationId: number
  created: string
  updated: string
  localized: BuildingLocalizations
  showHealthCommitment: boolean
  operatorName?: string
  address: string
  city: string
  cityObj: City
  country: string
  description: string
  imageUrls: string[]
  latitude: number
  longitude: number
  timezone: Timezone
  rooms: AdminBuildingRoom[]
  wifiName: string | null
  wifiPassword: string | null
  supportedPaymentMethods: PaymentMethod[]
  allowedReservationTimes: AllowedReservationTimes
  newReservationNotifications: boolean
  amenities: Amenity[]
  coordinates: CoordinatesBySystem
  publishingStatus: LocationPublishingStatus
  marketingImageUrl?: string
  lobbyUsergroupId?: number
  lobbyUsergroupReservationsOnly?: boolean
  useAutomaticNationalHolidays: boolean
  createWebPage: boolean
} & Omit<Lobby, 'content'>

export interface Coordinates {
  latitude?: number
  longitude?: number
}

export type PreReservationChecks = VisitorInfo

export type VisitorInfo = {
  title?: string
  content?: string
  checks?: string[]
}

export type LocalizedVisitorInfo = { [l in Language]?: VisitorInfo }

/**
 * Localized content of single field inside localized -jsonb column.
 *
 * Fe {
 *  fi: "Nimi",
 *  en: "Name",
 *  default: "en"
 * }
 */
export type LocalizedContent<T = string> = {
  [l in Language]?: T
} & {
  default?: Language
}

export type ReservationLimitType =
  | undefined
  | 'hours'
  | 'prev-open-day-time'
  | 'open-days-before'

export type ReservationLimits = {
  minReservationMinutes?: number
  maxReservationMinutes?: number
  maxReservableAdvanceLimitType?: ReservationLimitType
  maxReservableAdvanceLimit?: string
  maxReservableAdvanceHours?: number // for app support
  minReservableAdvanceLimitType?: ReservationLimitType
  minReservableAdvanceLimit?: string
  minReservableAdvanceHours?: number // for app support
  maxReservationMinutesPerDay?: number
  maxUpcomingReservationsPerUser?: number
}

export interface RoomOffer {
  id: number
  name: string
  label: string
  notes: string
  pricingData: PricingData
  offeringId: number

  maxReservationMinutes?: number
  minReservationMinutes?: number
  maxReservableAdvanceLimitType?: ReservationLimitType
  maxReservableAdvanceLimit?: string
  maxReservableAdvanceHours?: number

  minReservableAdvanceLimitType?: ReservationLimitType
  minReservableAdvanceLimit?: string
  minReservableAdvanceHours?: number

  maxReservationMinutesPerDay?: number
  maxUpcomingReservationsPerUser?: number

  limits: ReservationLimits
  roomIds: number[]
}

export function isExisting(thing: { id?: number }) {
  return thing.id !== 0
}

export interface RoomOfferForUsergroups extends RoomOffer {
  paymentMethods: string[] // TODO: Should be PaymentMethod[].
  usergroupIds: number[]
}

export interface PricingRule {
  hours: number

  /**
   * @deprecated  Deprecated, support this, but depend on priceWithoutTax instead.
   * For clarification: Price field includes some prices with and some without taxes
   * but priceWithoutTax should always be treated as price WIHTOUT tax.
   *
   * For new pricing, price and priceWithoutTax both should be identical price
   * without tax. In the future, price field should be removed sooner than later
   * depening on app usage.
   */
  price: number
  priceWithoutTax: number
}

type PricingStructure<T extends PricingType, U> = {
  type: T
  rules: U
}

export type TieredPricingByLength = PricingStructure<'byLength', PricingRule[]>
export const tieredUnits = ['hours', 'days', 'weeks', 'months'] as const
export type TieredUnit = typeof tieredUnits[number]
export const tieredUnitHourCounts: Record<TieredUnit, number> = {
  hours: 1,
  days: 8, // estimate for usual daily utilization time when comparing average hour vs day prices
  weeks: 8 * 5, // estimate for usual weekly utilization time when comparing average hour vs week prices
  months: 8 * 20, // estimate for usual monthly utilization time when comparing average hour vs monthly prices
}
export type TieredPrice = {
  uptoUnitCount: number
  perUnitPriceWithoutTaxes: number
}
export type TieredPricingByUnit = PricingStructure<
  'tieredByUnit',
  {
    unit: TieredUnit
    unitPrices: TieredPrice[]
  }
>

export type FreePricing = PricingStructure<'free', []>

export type VolumePricing = PricingStructure<
  'byVolume',
  {
    hourlyPriceWithoutTax: number
    dailyPriceWithoutTax: number
    weeklyPriceWithoutTax: number
    monthlyPriceWithoutTax: number
  }
>

export type HourlyPricing = PricingStructure<
  'hourly',
  [
    {
      hours: 1
      price: number
      priceWithoutTax: number
    },
  ]
>

type CoworkingPassFilterableFields = {
  offeringId?: number
  usergroupId?: number
}

export type PricingData =
  | (
      | TieredPricingByUnit
      | TieredPricingByLength
      | FreePricing
      | VolumePricing
      | HourlyPricing
    ) &
      CoworkingPassFilterableFields

export type PricingType = PricingData['type']

export interface RoomCalendarIntegration {
  calendarId: string
  calendarIntegrationId: number
}

export type Weekday = typeof weekDays[number]
export type AllowedReservationTimes = Record<Weekday, [string, string] | []>

export const DefaultAllowedReservationTimes: AllowedReservationTimes = {
  monday: ['0:00', '23:59'],
  tuesday: ['0:00', '23:59'],
  wednesday: ['0:00', '23:59'],
  thursday: ['0:00', '23:59'],
  friday: ['0:00', '23:59'],
  saturday: [],
  sunday: [],
}

export interface AvailableCalendarIntegration {
  id: number
  name: string
}

export type BookingNotificationSettings = {
  roomId?: number
  emails: string[]
  enabled: boolean
  language: Language
  timezone?: string
}

export type AdminReservableRoom = Pick<
  RoomOptions,
  | 'id'
  | 'name'
  | 'localized'
  | 'address'
  | 'building'
  | 'buildingId'
  | 'timezone'
  | 'floor'
  | 'roomImageUrls'
  | 'pricingByGroup'
  | 'pricingDataByGroup'
  | 'pricingData'
  | 'currencyCode'
  | 'capacity'
  | 'displayCapacity'
  | 'allowedDaysMax'
  | 'reservationType'
  | 'spaceType'
  | 'roomCalendarIntegration'
  | 'doors'
  | 'supportedPaymentMethods'
  | 'maxReservationMinutes'
  | 'minReservationMinutes'
  | 'maxReservableAdvanceHours'
  | 'minReservableAdvanceHours'
  | 'maxReservationMinutesPerDay'
  | 'maxUpcomingReservationsPerUser'
  | 'organizationId'
  | 'rating'
  | 'amenities'
  | 'coordinates'
  | 'publishingStatus'
  | 'isUnavailable'
  | 'connectedRoomIds'
  | 'hasConnectedRooms'
  | 'preReservationBufferMins'
  | 'postReservationBufferMins'
  | 'cancellableHoursBeforeStart'
  | 'includedInMeetingRoomPackage'
  | 'consumerPayPerUseEnabled'
  | 'businessPayPerUseEnabled'
  | 'icalId'
> & {
  allowedReservationTimes?: AllowedReservationTimes
  defaultCalendarIntegrationInputType?: IntegrationInputType
  availableCalendarIntegrations: AvailableCalendarIntegration[]
  buildingAllowedReservationTimes: AllowedReservationTimes
  bookingNotifications?: BookingNotificationSettings
  showPreReservationChecks?: boolean
  defaultReservationLimits?: ReservationLimits
}

export type IntegrationInfo = {
  calendarIntegration?: {
    name: string
    id: string
    disabled?: boolean
  }
  accessIntegrations?: {
    name: string
    id: string
    disabled?: boolean
  }[]
}

export type AdminReservableRoomWithIntegrationInfo = AdminReservableRoom & {
  integrationInfo?: IntegrationInfo
}

export interface InReviewRoomlistItem {
  roomId: number
  name: LocalizedContent
  buildingName: string
  organizationId: number
  organizationName: string
  publishingStatus: Extract<PublishingStatus, 'inReview'>
  updated?: DateISOString
}

export type RoomOfferBindingId = number | 'OrganizationInternalFree'

export interface RoomOfferBinding {
  offerId: number
  roomId: number
}

export type UserReservableRoom = Omit<
  RoomOptions,
  'pricingByGroup' | 'localizedDescriptions'
> & {
  ownCapacityReservations?: ReservedSlot[]
  disabledWithReason?: string
  roomListTag?: string
}

export type UserReservableRoomWithOrganization = UserReservableRoom & {
  ownOrganization: boolean
}

export type Colleague = {
  id: number
  organizationId: number
  name: string
  email: string
  phone: string
  avatarUrl?: string
}

export interface ReservedSlot {
  start: Date
  end: Date
  colleague?: Colleague
  created?: Date
  origin?: string
}

export type MinutesPerDay = Record<DateISOString, number>

export interface RoomReservedSlots {
  roomId: number
  allSlots: ReservedSlot[]
  ownCapacitySlots: ReservedSlot[]
  ownReservedMinutes: MinutesPerDay
  ownUpcomingReservationsCount: number
}

export type ReservedSlotsByRoomId = { [roomId: number]: ReservedSlot[] }

export interface AdminReservedSlot extends ReservedSlot {
  id: string
  modifiable: boolean
  roomId: number
  externalEditable: boolean
}

export interface Access {
  id?: number
  externalId: string
  pin?: string
  url?: string
  start?: Moment
  end?: Moment
}

export interface DoorAccess extends Access {
  doorId: number
}

// Reservation is a reservation from local db
export interface Reservation {
  end: string
  id: number
  created?: string
  invoiceReference?: string
  participants: number
  paymentMethod: PaymentMethod
  paymentStatus: ReservationPaymentStatus
  price: number
  products: ProductQuantity[]
  start: string
  reservedFrom: ClientType
  doorPin?: string // Legacy for app version < 1.5.27
  doorUrl?: string // Legacy for app version < 1.5.27
  accesses: DoorAccess[]
  feedbackPending: boolean
  shareableId: string
  stripePaymentIntentId?: string
  organizationId?: number
  currency?: CurrencyCode
  userId?: number
  reservedFor?: string
  roomId: number
  taxType: TaxType
  taxPercentage: number
  pricingSummary?: PricingSummary
  visibleToColleagues?: boolean
  hostOrganizationId?: number
  invitedFromReservationId?: number
  cancelled?: string
  cancellerType?: CancellingUserType
  cancelledMessage?: string
  cancellerUserId?: number
  isTeamAccessReservation?: boolean
  eventId?: string
}

export type RoomTimeRange = Pick<Reservation, 'roomId' | 'start' | 'end'>

export interface ReservationBundle {
  room: UserReservableRoom
  reservation: Reservation
  offers: Offer[]
  products: Product[]
  building: Building | null
}

export type ReservationInfoRelevantForUsers = {
  roomName: string
  roomAddress: string
  roomImageUrls: string[]
  shareableId: string
  reservationStart: string
  reservationEnd: string
  roomTimezone: string
  roomId?: number
  reservationId?: number
  locationName?: string
}

export type InvitationEmailInfo = {
  room: Pick<UserReservableRoom, 'name' | 'address' | 'roomImageUrls'>
  reservation: Pick<Reservation, 'doorPin' | 'doorUrl'>
  building: Pick<
    Building,
    | 'arrivalInfo'
    | 'visitorInfoChecks'
    | 'visitorInfoContent'
    | 'visitorInfoTitle'
  > | null
  amenities?: string
  checkInUrl?: string
  cancellationUrl?: string
  visitTime: string
}

export type AdminReservationType =
  | 'reservation'
  | 'connected-reservation'
  | 'buffer'
  | 'reservation-from-integration'

export interface AdminReservation {
  end: Date
  invoiceReference?: string
  modifiable: boolean
  externalEditable: boolean
  name?: string
  notes?: string
  organizationId?: number
  paymentMethod: PaymentMethod
  phone?: string
  email?: string
  price?: number
  currencyCode: CurrencyCode
  products: AdminProductQuantity[]
  reservationId?: number
  reservedFor?: string
  reservedFrom: ClientType
  reservedForUserId?: number
  room: AdminReservableRoom
  roomId: number
  start: Date

  // Optional due to refactoring troubles. This type has no central place of fetching and its used everywhere...
  shareableId?: string
  type?: AdminReservationType
  originRoomForConnectedReservation?: AdminReservableRoom
}

export interface UnavailabilitySlot {
  start: Date
  end: Date
  created?: Date
  origin?: string
}

export type UnavailabilitiesByRoomId = {
  [roomId: number]: UnavailabilitySlot[]
}

export interface RoomUnavailability {
  id?: number
  roomId: number
  timezone: string
  start: string
  end: string
  description?: string
}

export type NewRoomUnavailability = Omit<RoomUnavailability, 'id' | 'timezone'>

export interface RoomIntegrationError {
  roomId: number
  error: string
}

export interface RoomsWithReservations {
  reservations: AdminReservation[]
  rooms: AdminReservableRoom[]
  unavailabilities: RoomUnavailability[]
  integrationErrors?: RoomIntegrationError[]
}

export type RoomIncomplete = Partial<Room>

export interface BuildingIncomplete {
  name: string
  rooms: RoomIncomplete[]
  actualBuildingId?: number
}

export interface TaxRateRegion {
  countryCode: TaxRateCountryCode
  regionalState?: string
  city?: string
}

export interface InviteEmail {
  email: string
  organizationJoined?: boolean
}

export interface InviteEmailWithId extends InviteEmail {
  invitationId: string
}

export interface Usergroup {
  name: string
  id: number
  organizationId?: number
  organizationName?: string
  users?: User[]
  organizations?: Organization[]
  global: boolean
  ownerUserId?: number
  visibleToUsergroupId?: number | null
  managedByUsergroupId?: number | null

  // Currently only working as an simplified abstraction for MGMT tool edit form.
  showInAppForOwnOrganization?: boolean
  usersCanLeaveFreely?: boolean

  orderingNumber?: number | null
}

export type NewUsergroup = Required<
  Pick<
    Usergroup,
    | 'name'
    | 'organizationId'
    | 'showInAppForOwnOrganization'
    | 'usersCanLeaveFreely'
    | 'ownerUserId'
  >
>

export interface Offer {
  title: string
  description: string
  productId: number
}

export type ReservationOptions = Pick<
  Reservation,
  | 'start'
  | 'end'
  | 'invoiceReference'
  | 'participants'
  | 'paymentMethod'
  | 'products'
  | 'reservedFrom'
  | 'visibleToColleagues'
  | 'invitedFromReservationId'
>

export interface FeedbackTag {
  id: string
  translations: FeedbackTagTranslation[]
}

export interface FeedbackTagTranslation {
  languageCode: string
  value: string
}

export interface ReservationFeedback {
  reservationId: number
  rating: number
  tags: FeedbackTag[]
  openFeedback: string
}

export interface LocationFeedback {
  id: number
  roomId: number
  roomName: string
  buildingId: number
  buildingName: string
  created: DateISOString
  startTime: DateISOString
  endTime: DateISOString
  rating: number
  openFeedback: string
  response?: string
  responseAt?: DateISOString
  reservationShareableId: string
}

export interface FeedbackLocation {
  buildingId: number
  buildingName: string
  avgRating: number
  openFeedbackCount: number
}

export interface QuickFeedback {
  source: string
  openFeedback: string
}

export type CloudinaryDirectory = 'spaces' | 'locations'

export type FeatureFlags = Record<string, boolean>
export type FrontEndConfig = Record<string, string>

export type PossibleUserFeatureFlag = typeof possibleUserFeatureFlags[number]
export type UserFeatureFlags = Record<PossibleUserFeatureFlag, boolean>

export type Configuration = {
  features: { [key: string]: boolean }
  config: FrontEndConfig
}

export type RedirectUrlKey = keyof Pick<
  LegislationRegion,
  'generalTermsAndConditionsUrl' | 'userTermsUrl' | 'privacyPolicyUrl'
>

export interface UserLegalDocumentsRequest {
  regionId: number
}

export interface LegalDocumentSummaryElement {
  icon: string
  content: string
}

export interface UserLegalDocumentsResponse {
  summary: LegalDocumentSummaryElement[]
  markdown: string
}

export type DateISOString = string

export type UpdateOfferRequest = {
  offer: RoomOffer
}

export type CreateNewOfferRequest = {
  offer: RoomOffer
}

export const longTermTokenIntents = ['session'] as const
export const temporaryTokenIntents = [
  'add-credit-card',
  'authorize-payment',
  'accept-invite',
] as const

export const tokenIntents = [...longTermTokenIntents, ...temporaryTokenIntents]

export type TemporaryTokenIntent = typeof temporaryTokenIntents[number]
export type LongTermTokenIntent = typeof longTermTokenIntents[number]
export type TokenIntent = LongTermTokenIntent | TemporaryTokenIntent

export type TemporaryTokenRequest = {
  intent: TemporaryTokenIntent
}

export type EmailToken = string

export type TemporaryToken = {
  token: string
  expiration: DateISOString
  intent: TemporaryTokenIntent
  temporary: true
}
export type TemporaryTokenResponse = TemporaryToken
export type MomentTimeInclusivity = '()' | '[)' | '(]' | '[]'

export enum PersonalInfoLevel {
  anonymous = 'anonymous',
  pseudonymous = 'pseudonymous',
  personal = 'personal',
}

export interface StripeCoupon {
  stripeCouponId: string
  percentOff: number
  durationInMonths?: number
}

export interface Discount extends StripeCoupon {
  id: number
  contentfulMarketingBundleId?: string
}

export type UserPosition = Required<Coordinates> & {
  activity: string
  params: Record<string, any>
}

export interface UserNotificationToken {
  token: string
  deviceLocale: string
}

export type PushNotificationTokenAndLanguage = {
  token: string
  deviceLocale: Language
}

export interface ReservationInfoForPushNotification {
  reservationId: number
  reservationUuid: string
  startTime: Moment
  userId: number
  tokensWithLanguage: PushNotificationTokenAndLanguage[]
  cancellableUntil: Moment
}

export interface CustomNetworkCoverageBuilding {
  id: number
  coordinates: CoordinateData
}
export interface CustomNetworkCoverage {
  activeCount: number
  active: CustomNetworkCoverageBuilding[]
  disabled: CustomNetworkCoverageBuilding[]
  bbox: number[]
}

export type QuestionnaireQuestionOption = {
  title: string
  value: string
}

export type QuestionnaireQuestion = {
  id: string
  title: string
  description?: string
} & {
  options: QuestionnaireQuestionOption[]
}

export type Questionnaire = {
  id: string
  questions: QuestionnaireQuestion[]
}

export type UserHomeAddressOrigin = 'onboarding' | 'profile' | 'prompt'

export type UserHomeAddress = {
  origin: UserHomeAddressOrigin
  streetAddress?: string
  postalCode?: string
  latitude?: number
  longitude?: number
}

export type UserCommutePreference =
  | 'car'
  | 'publicTransport'
  | 'walkOrBike'
  | 'dontKnow'

export type UserCommuteRequest = {
  origin: UserHomeAddressOrigin
  preference: UserCommutePreference
}
export interface BillingReservationRow {
  id: number
  created: DateISOString
  updated: DateISOString
  start_time: DateISOString
  end_time: DateISOString
  cancelled: DateISOString | null
  cancellable_hours_before_start: number
  event_id: string | null
  currency: string
  receipt_rows: any
  reserved_for: string | null
  notes: string | null
  invoice_reference: string | null
  reserved_from: string
  price: number
  taxAmount: number
  priceWithTax: number
  tax_percentage: number
  tax_type: string | null
  capacity: number
  room_id: number
  pricing_summary: any
  space_type: string
  room_name: string
  location_operator_name: string
  location_address: string
  location_country: string
  timezone: string
  building_id: number
  user_id: number
  payment_method: string
  created_after_mrq_change: boolean
  user_name: string | null
  user_email: string | null
  user_phone: string | null
  usergroups: string | null
  organization_id: number | null
  organization_name: string | null
  organization_identifier: string | null
  organization_e_invoice_operator: string | null
  organization_e_invoice_address: string | null
  organization_invoice_contact: string | null
  organization_invoice_type: string | null
  organization_pdf_invoice_receiver: string | null
  target_organization_name: string
  target_organization_id: number
  target_organization_identifier: string
  commissionPercentage?: number
  hostPayment?: number
  hostPaymentTax?: number
  hostPaymentWithTax?: number
  hostPaymentTaxPercentage?: number
  commission?: number
  commissionTax?: number
  commissionWithTax?: number
  purchasePrice?: number
  purchasePriceTax?: number
  purchasePriceWithTax?: number
}

export type JSONObject = Record<string, any>

export type OrganizationSettingsResponse = OrganizationSettings | undefined
